<script>
  import axios from "@/api/axios";
  import alerts from "@/js/swalAlerts";
  import ToggleInput from "@/components/input/ToggleInput.vue";
  import { socket } from "@/socket";
  import ModalCreateCategory from "../News/CategoriesNews/ModalCreateCategory.vue";
  import Cropper from "cropperjs";
  import "cropperjs/dist/cropper.css";

  export default {
    data() {
      return {
        error: false,
        categories: [],
        showModalCategory: false,
        category: "",
        thumbnail: false,
        resetKey: 0,
        resetKeyFile: 1000,
        resetKeyImg: 2000,
        resetKeyVideo: 3000,
        resetKeyThumbnail: 4000,
        initCheck: true,
        dateFrom: undefined,
        dateTo: undefined,
        roles: this.$global.dictionary.roles_es_auto,
        cities: [],
        isEdit: false,
        isEditData: null,
        checkall: false,
        roleAll: false,
        featured: false,
        reminder: false,
        title: "",
        subtitle: "",
        forUser: [],
        mountedNews: false,
        imgs: [
          { icon: "fa fa-bold", params: "bold" },
          { icon: "fa fa-italic", params: "italic" },
          { icon: "fa fa-underline", params: "underline" },
          { icon: "fa fa-align-left", params: "justifyleft" },
          { icon: "fa fa-align-center", params: "justifycenter" },
          { icon: "fa fa-align-right", params: "justifyright" },
          { icon: "fa fa-list-ul", params: "insertunorderedlist" },
          { icon: "fa fa-list-ol", params: "insertorderedlist" },
        ],
        elemetsizeText: {
          1: "10px",
          2: "13px",
          3: "16px",
          4: "19px",
          5: "22px",
          6: "25px",
          7: "28px",
        },
        link: "",
        fontSize: 4,
        colorShowSub: true,
        colorSub: "#000000",
        color: "#000000",
        showEditText: false,
        img: "",
        cont: 0,
        modifyVideo: false,
        nameFile: "",
        time: 0,
        timer: null,
        colorShow: true,
        event: null,
        showButton: false,
        showRecicleButton: false,
        isLoadingThubnail: true,
        addImage: false,
        isLoading: false,
        transfer_id: "",
        show_btn_delet: false,
        officesData: [],
        checkedOffices: [],
        selectedNumber: 1,
      };
    },
    components: {
      ToggleInput,
      NewsFor: () => import("./NewsFor.vue"),
      ModalCreateCategory,
      ModalCreateCategory,
    },
    computed: {
      initSocketEvents() {
        if (this.$socketState) {
          socket.on("loaderPercent", this.onLoaderPercent);
        }
      },
    },
    beforeDestroy() {
      socket.off("loaderPercent", this.onLoaderPercent);
    },
    watch: {
      checkall(newValue) {
        if (newValue) {
          this.fetchOffices();
        }
      },
      color() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.colorShow = false;
          setTimeout(() => {
            this.colorShow = true;
          }, 0);
        }, 800);
      },
    },
    methods: {
      async fetchCities() {
        try {
          const response = await axios.get("/cities/");
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            this.cities.push(element.cityName);
          }
        } catch (error) {
          console.error("Error al obtener oficinas:", error);
        }
      },
      checkHeadquaters() {
        this.fetchOffices();
        const cities = Object.values(this.cities);
        for (let index = 0; index < cities.length; index++) {
          let element = cities[index];
          const inputElements = document.querySelectorAll(`input[name="cities-${element}"]`);
          if (inputElements[0].checked) {
            for (const objeto of this.officesData) {
              const comparison = objeto.cityName.localeCompare(element, "es", { sensitivity: "base" });
              if (comparison === 0) {
                const input = document.querySelectorAll(`input[name="office-${objeto.officeName}"]`);
                input.forEach((elem, index) => {
                  this.$set(elem, "checked", true);
                });
              }
            }
          } else {
            for (const objeto of this.officesData) {
              const comparison = objeto.cityName.localeCompare(element, "es", { sensitivity: "base" });
              if (comparison === 0) {
                const input = document.querySelectorAll(`input[name="office-${objeto.officeName}"]`);
                input.forEach((elem, index) => {
                  this.$set(elem, "checked", false);
                });
              }
            }
          }
        }
      },
      async fetchOffices() {
        try {
          const response = await axios.get("/offices/");
          this.officesData = response.data;
        } catch (error) {
          console.error("Error al obtener oficinas:", error);
        }
      },
      async showImages() {
        this.isLoading = true;
        this.$route.query.state = "getImages";
        this.addImage = true;
        let res = await axios.get(`/news/id?id=${this.$route.query.new}&state=${this.$route.query.state}`);
        this.convert(res.data.imagenPath);
        this.isLoading = false;
      },
      async getCategories() {
        let response = await this.$store.dispatch("newsCategories/getCategories");
        this.categories = response;
      },
      showModal() {
        this.showModalCategory = true;
      },
      closeModal(data) {
        this.showModalCategory = !data;
        this.getCategories();
      },

      readFileAndRender(file) {
        this.show_btn_delet = true;
        return new Promise((resolve) => {
          let extension = this.cutName(file.type);
          let visor = new FileReader();
          visor.onload = function(e) {
            let cropper;
            if (extension === "jpeg" || extension === "png" || extension === "jpg" || extension === "gif" || extension == "webp") {
              document.getElementById("visorArchivo").innerHTML += '<img draggable="true" data-key="key" class="visor" name="visor" src="' + e.target.result + '" width="125" height="65" />';
              let arrayImages = document.getElementsByClassName("visor");
              let counter = 0;

              function cutImage(e) {
                let image = document.getElementById("img-cropper");
                image.src = e.src;
                cropper = new Cropper(image, {
                  aspectRatio: 2,
                  preview: ".img-sample",
                  zoomable: false,
                  viewMode: 2,
                  responsive: false,
                  dragMode: "none",
                  modal: true,
                  ready() {
                    document.querySelector(".cropper-container").style.width = "100%";
                    document.querySelector(".cropper-container").style.height = "100%";
                  },
                });
              }
              function cut(img, id) {
                let canva = cropper.getCroppedCanvas();
                let image = document.getElementById("img-cropper");
                let input = document.getElementById("imageFileValid");
                let embedCut = document.getElementById(id);

                canva.toBlob(function(blob) {
                  blob.lastModified = new Date();
                  let pattern = /\-(.*)/;
                  let newId = img.id.match(pattern);
                  let id = newId[1];

                  let originalName = img.src.substring(img.src.lastIndexOf("/") + 1).substring(0, 9);

                  const myFile = new File([blob], `${originalName}-${id}.jpg`, {
                    type: blob.type,
                  });

                  const dataTransfer = new DataTransfer();
                  dataTransfer.items.add(myFile);
                  embedCut.src = URL.createObjectURL(blob);
                  if (input.files.length) {
                    let oldArray = [...input.files];
                    let arrayOut = [];
                    for (let i = 0; i < input.files.length; i++) {
                      if (parseInt(id) !== i) {
                        const dt = new DataTransfer();
                        const fileInput = document.getElementById("imageFileValid");
                        const { files } = fileInput;
                        for (let i = 0; i < files.length; i++) {
                          const file = files[i];
                          if (file[i] !== i) {
                            dt.items.add(file);
                          }
                        }
                        dataTransfer.items.add(input.files[i]);
                        fileInput.files = dt.files;
                      }
                    }
                    input.files = dataTransfer.files;
                    let newArray = [...input.files];
                    let archiveOut;
                    for (let i = 0; i < newArray.length; i++) {
                      for (let x = 0; x < oldArray.length; x++) {
                        oldArray[i].name === newArray[x].name && arrayOut.push(oldArray[i]);
                        if (newArray[x].name === `${originalName}-${i}.jpg`) {
                          archiveOut = newArray[x];
                          let pattern = /\-(.*)/;
                          let newId = img.id.match(pattern);
                          let index = parseInt(newId[1]);
                          arrayOut.splice(index, 0, archiveOut);
                        }
                      }
                    }
                    let arrayFiles = [...new Set(arrayOut)];
                    let dt = new DataTransfer();
                    for (let i = 0; i < arrayFiles.length; i++) {
                      dt.items.add(arrayFiles[i]);
                    }
                    input.files = dt.files;
                  }
                });

                image.src = "";
                let elem = document.getElementById("containerCropper");
                elem.parentNode.removeChild(elem);
                cropper.destroy();
              }
              function dragOver(event) {
                event.preventDefault();
              }
              function dragStart(event) {
                event.dataTransfer.setData("text/plain", event.target.id);
              }
              function drop(event) {
                event.preventDefault();
                const imageId = event.dataTransfer.getData("text/plain");
                const draggedImage = document.getElementById(imageId);
                const targetImage = event.target;
                if (draggedImage && targetImage && targetImage.parentNode) {
                  const container = targetImage.parentNode;

                  if (draggedImage !== targetImage) {
                    const draggedIndex = Array.from(container.children).indexOf(draggedImage);
                    const targetIndex = Array.from(container.children).indexOf(targetImage);

                    if (draggedIndex < targetIndex) {
                      container.insertBefore(draggedImage, targetImage.nextSibling);
                    } else {
                      container.insertBefore(draggedImage, targetImage);
                    }

                    const arrayImages = document.getElementsByClassName("visor");
                    for (let i = 0; i < arrayImages.length; i++) {
                      arrayImages[i].id = `cor-${i}`;
                    }

                    const input = document.getElementById("imageFileValid");
                    const files = Array.from(input.files);
                    const draggedFile = files[draggedIndex];

                    files.splice(draggedIndex, 1);
                    files.splice(targetIndex, 0, draggedFile);

                    const dt = new DataTransfer();
                    files.forEach((file) => {
                      dt.items.add(file);
                    });
                    input.files = dt.files;
                  }
                }
              }

              function agregate(image) {
                if (document.getElementById("modal").innerHTML === "") {
                  document.getElementById("modal").innerHTML +=
                    '<div id="containerCropper" class="modalRecortes"><div class="modal-content"><div class="modal-header"><p>Recorta tu foto</p></div><div class="modal-body"><div class="content-imagen-cropper"><img src="" alt="" class="img-cropper" id="img-cropper" /></div><div class="modal-footer"><span class="btn primary" id="cut" name="cut">Recortar</span><span type="button" class="btn secundary" id="close" name="close">Cancelar</span></div><div><div src="" alt="" class="img-sample" id="img-croppered" style="height:450px; width:675px">Imagen aqui</div></div></div></div></div></div>';
                } else {
                  let elem = document.getElementById("containerCropper");
                  elem.parentNode.removeChild(elem);
                }
                document.getElementById("close").addEventListener("click", function() {
                  let elem = document.getElementById("containerCropper");
                  elem.parentNode.removeChild(elem);
                });
                document.getElementById("cut").addEventListener("click", function() {
                  cut(image, image.id);
                });
                cutImage(image);
              }

              function addClickEvent(image) {
                image.addEventListener("click", function() {
                  agregate(image);
                });
                image.addEventListener("dragstart", dragStart);
                image.addEventListener("dragover", dragOver);
                image.addEventListener("drop", drop);
                image.draggable = true;
              }
              for (let image of arrayImages) {
                image.id = `cor-${counter++}`;
                addClickEvent(image);
              }

              let deleteImage = document.querySelector(".container_trash");
              deleteImage.addEventListener("click", deleteImages);
              let a = [];

              function deleteImages() {
                let close = document.querySelector(".closeSlider");
                close.style.display = "block";

                for (let i = 0; i < arrayImages.length; i++) {
                  const image = arrayImages[i];
                  const newElement = image.cloneNode(true);
                  image.parentNode.replaceChild(newElement, image);
                  let isFilterApplied = false;
                  newElement.addEventListener("click", function() {
                    newElement.style.filter = "sepia(100%) hue-rotate(245deg)";
                    newElement.style.backgroundColor = "red";
                    let result = i;
                    if (isFilterApplied) {
                      newElement.style.filter = "";
                      isFilterApplied = false;
                      let indice = a.indexOf(parseInt(result));
                      if (indice !== -1) {
                        a.splice(indice, 1);
                      }
                    } else {
                      newElement.style.filter = "sepia(100%) hue-rotate(0deg)";
                      isFilterApplied = true;
                      a.push(parseInt(result));
                    }
                    if (a.length >= 1) {
                      let nuevoElemento = document.createElement("span");
                      nuevoElemento.classList.add("borrar");
                      const elementosBorrar = document.getElementsByClassName("borrar");
                      for (let j = 0; j <= elementosBorrar.length - 1; j++) {
                        elementosBorrar[j].remove();
                      }
                      nuevoElemento.textContent = `Borrar ${a.length} imagenes selecionadas`;
                      close.insertAdjacentElement("beforebegin", nuevoElemento);
                      deleteImage.style.display = "none";
                    }

                    document.querySelector(".borrar").addEventListener("click", function() {
                      const input = document.getElementById("imageFileValid");
                      const dt = new DataTransfer();
                      const indicesABorrar = a;
                      for (let j = 0; j < input.files.length; j++) {
                        if (!indicesABorrar.includes(j)) {
                          dt.items.add(input.files[j]);
                        }
                      }
                      input.files = dt.files;
                      close.click();
                    });
                  });
                }
                close.addEventListener("click", function() {
                  close.style.cssText = "display:none;";
                  deleteImage.style.display = "block";
                  document.querySelector(".borrar").remove();
                });
              }
            }
            if (extension === "mp4" || extension === "mov" || extension === "avi" || extension === "mkv" || extension === "flv" || extension === "rm" || extension === "quicktime") {
              document.getElementById("visorArchivo").innerHTML +=
                '<video draggable="true" data-key="key" autoplay muted loop class="visor" src="' + e.target.result + '" width="125" height="65"></video>';
            }
            resolve();
          };

          visor.readAsDataURL(file);
        });
      },
      async GetFileInfo() {
        let fileInput = document.getElementById("imageFileValid");
        if (fileInput.value !== "") {
          this.addImage = true;
        }
        let archivoRuta = fileInput.value;
        let extPermitidas = /(.qtff|.QTFF|.MOV|.mov|.AVI|.avi|.MKV|.mkv|.FLV|.flv|.WMV|.wmv|.MP4|.mp4|.GIF|.gif|.PNG|.png|.JPG|.jpg|.JPEG|.jpeg|.webp|.WEBP)$/i;
        let message = "";
        if ("files" in fileInput) {
          if (fileInput.files.length == 0 || !extPermitidas.exec(archivoRuta)) {
            message = "Por favor eliga uno o más archivos.";
            if (!extPermitidas.exec(archivoRuta)) {
              message = "Por favor, asegurese de que eliga una imagen";
            }
          } else {
            if (fileInput.files.length) {
              document.getElementById("visorArchivo").innerHTML = "";
              for (let i = 0; i < fileInput.files.length; i++) {
                await this.readFileAndRender(fileInput.files[i]);
              }
            }
          }
        }
      },
      moreImages() {
        let fileInput = document.getElementById("imageAdd");
        let filesOriginal = document.getElementById("imageFileValid");
        let moreImages = [...fileInput.files];
        let arrayOriginal = [...filesOriginal.files];
        let totalImages = arrayOriginal.concat(moreImages);
        let arrayFiles = [...new Set(totalImages)];
        let dt = new DataTransfer();
        for (let i = 0; i < arrayFiles.length; i++) {
          dt.items.add(arrayFiles[i]);
        }
        filesOriginal.files = dt.files;
        this.GetFileInfo();
      },
      showNameFile(data) {
        let arrayImages = [];
        for (let i = 0; i < data.length; i++) {
          arrayImages.push(this.cutName(data[i]));
        }
        return arrayImages;
      },
      async setFileInfo(files, isEdit) {
        let elementExist = document.getElementsByClassName("newNews__containerVideos");
        this.cont = 1;
        while (this.cont) {
          elementExist[0]?.parentNode?.removeChild(elementExist[0]);
          this.cont--;
        }
        let video = document.createElement("video");
        video.preload = "metadata";
        video.classList = "newNews__containerVideos";
        video.src = URL.createObjectURL(files[0]);
        video.setAttribute("controls", "controls");
        this.cont = 0;
        return new Promise(async function(resolve) {
          video.onloadedmetadata = function() {
            document.getElementById("containerVideoPath")?.appendChild(video);
            resolve({ time: this.time });
          };
        });
      },
      async CreateImgsSelect(event) {
        const { isConfirmed } = await alerts.dataSendConfirm({
          title: "Creación del Póster",
          message: " ¿Deseas generar el póster del video?",
          buttonTextNo: "No",
          buttonTextYes: "Si",
        });
        if (!isConfirmed) {
          return;
        }
        this.event = event;
        if (event?.target?.files[0]?.size < 1) return;
        let elementExist = document.getElementsByClassName("newNews__imgContentVideo");
        while (this.cont) {
          elementExist[0].parentNode?.removeChild(elementExist[0]);
          this.cont--;
        }
        this.setFileInfo(event.target.files).then(() => (this.showButton = true));
      },
      deletePoster() {
        let elementExist = document.getElementsByClassName("newNews__imgContentVideo");
        while (this.cont) {
          elementExist[0].parentNode?.removeChild(elementExist[0]);
          this.cont--;
        }
        this.modifyVideo = false;
      },

      createElementVideo() {
        this.showRecicleButton = false;
        let elementExist = document.getElementsByClassName("newNews__imgContentVideo");
        while (this.cont) {
          elementExist[0].parentNode?.removeChild(elementExist[0]);
          this.cont--;
        }
        let video = document.querySelector(".newNews__containerVideos");
        video.pause();
        this.time = video.currentTime;
        if (this.event?.target) {
          let name = this.event.target.files[0];
          let div = document.createElement("div");
          div.classList = "spinner";
          document.getElementById("spinner")?.appendChild(div);
          if (name?.name !== this.nameFile) {
            this.isLoadingThubnail = false;
            this.videoCanva(this.time, this.event);
          }
        }
        this.time = 0;
      },
      async videoCanva(time, event) {
        try {
          this.modifyVideo = true;
          let file = event.target.files[0];
          let fileReader = new FileReader();
          let self = this;
          fileReader.onload = function() {
            let blob = new Blob([fileReader.result], { type: file.type });
            let url = URL.createObjectURL(blob);
            let video = document.createElement("video");
            let snapImage = function() {
              let canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
              let image = canvas.toDataURL();
              let success = image.length > 100000;
              if (success) {
                let img = document.createElement("img");
                img.src = image || "";
                img.classList = "newNews__imgContentVideo";
                img.setAttribute("id", "thubnailCreation");
                img.alt = "imgage";
                self.cont++;
                img.onerror = function(e) {
                  console.log("error", e);
                };
                document.getElementById("contentimage")?.appendChild(img);
                self.showRecicleButton = true;
                let a = document.getElementById("spinner");
                if (a) a?.remove();
                self.isLoadingThubnail = true;
              }
              return success;
            };
            let timeupdate = function() {
              if (snapImage()) {
                video.removeEventListener("timeupdate", timeupdate);
                video.pause();
              }
            };
            video.addEventListener("timeupdate", timeupdate);
            video.preload = "metadata";
            video.src = url + "#t=" + time;
            video.muted = true;
            video.playsInline = true;
            video.play();
          };
          fileReader.readAsArrayBuffer(file);
          return;
        } catch (err) {
          console.log(err.message);
        }
      },
      dataURLtoFile(dataurl, filename) {
        try {
          let arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr], filename, { type: mime });
        } catch (e) {
          console.log(e);
        }
      },
      setValueImg(event) {
        this.img = this.dataURLtoFile(event.src, "thumbnail.png");
      },
      onLoaderPercent(i) {
        this.$alerts.onChangeProgress(i);
      },
      selectedUsers: function(users) {
        this.forUser = users;
      },
      featuredToggle(model) {
        this.featured = model;
      },
      featureToggle(model) {
        this.reminder = model;
      },
      onChangeItem(evt, el) {
        if (!evt.target.checked) {
          const check = document.getElementById(el);
          check.checked = false;
        }
      },
      formatDoc(p, params) {
        if (p !== "foreColor" && p !== "fontSize") {
          document.execCommand(p, false);
          document.execCommand("foreColor", false, this.color);
          return;
        }
        if (p === "fontSize") {
          document.execCommand("fontSize", false, `${this.fontSize}`);
          return;
        }
        document.execCommand("foreColor", false, this.color);
        document.execCommand(p, false, params);
      },
      change(evt, className) {
        const cities = document.querySelectorAll(className);
        cities.forEach((el) => {
          if (evt.target.checked) el.checked = true;
          else el.checked = false;
        });
      },
      cutName(url) {
        const _url = url.split("/");
        return _url.length > 1 ? _url.pop().replaceAll("%20", " ") : _url;
      },
      validFileAlert(typeFile, id) {
        let value = document.getElementById(`${id}`).files;
        let validFile = this.$global.validFile(value, typeFile);
        const _dic = {
          videoFileValid: "videoNews",
          thumbnailFileValid: "thumbnail",
          imageFileValid: "imageNews",
          fileFileValid: "documentNews",
        };
        if (!this.isEditData) this.isEditData = {};

        if (!validFile) {
          let type = "";
          typeFile === "image" ? (type = "imagen") : typeFile === "video" ? (type = "video") : (type = "archivo");
          let msg = `El archivo debe ser de tipo ${type}`;
          this.isEditData[_dic[id]] = "";
          if (!this.isEditData?.videoNews) {
            this.isEditData.thumbnail = null;
            this.thumbnail = false;
          }
          this.clearFiles(id);
          alerts.dataSendError({ message: msg });
          return;
        }
        if (typeFile === "video") this.thumbnail = true;
        this.isEditData[_dic[id]] = value[0]?.name;
      },
      clearDate() {
        this.dateFrom = undefined;
        this.dateTo = undefined;
      },
      clearForm() {
        this.resetKey++;
        this.dateFrom = null;
        this.dateTo = null;
        this.initCheck = false;
        if (this.isEdit) {
          this.isEditData.userType = [];
          this.isEditData.city = [];
          this.title = null;
          this.subtitle = null;
          this.isEditData.textNews = null;
          this.isEditData.imageNews = null;
          this.isEditData.videoNews = null;
          this.isEditData.documentNews = null;
          this.isEditData.thumbnail = null;
          this.checkall = false;
          this.roleAll = false;
          this.forUser = [];
        }
      },
      clearFiles(typeFile) {
        switch (typeFile) {
          case "imageFileValid":
            this.resetKeyImg++;
            break;
          case "videoFileValid":
            this.resetKeyVideo++;
            break;
          case "fileFileValid":
            this.resetKeyFile++;
            break;
          case "thumbnailFileValid":
            this.resetKeyThumbnail++;
            break;
        }
        return;
      },
      dateLocaleFormat: (date) => {
        date = new Date(new Date(date).getTime() + 60 * 60 * 24 * 1000);
        const ulang = localStorage.getItem("user-language")?.substring(0, 2) || navigator.language.substring(0, 2);
        const options = {
          month: "short",
          day: "numeric",
          year: "numeric",
        };
        return date.toLocaleDateString(ulang, options);
      },
      handleDate: async function(data, values) {
        this.dateFrom = values[0];
        this.dateTo = values[1];
      },
      async sendFormNews() {
        document.querySelector("#thubnailCreation")?.src ? this.setValueImg(document.querySelector("#thubnailCreation")) : "";
        const textNews = document.getElementById("text-area-news").innerHTML;
        const _data = new FormData(document.getElementById("form-news-data"));
        let values = new Map([..._data]);
        if (this.category === "") {
          document.getElementById("selectCategory").style.border = "1px solid red";
          this.error = true;
          return;
        }
        const fileInput = document.querySelector("#imageFileValid");
        const files = fileInput.files;
        if (this.isEdit && files.length == 0) {
          for (let i = 0; i < this.isEditData.imageNews.length; i++) {
            _data.append(`imageNews${i}`, this.isEditData.imageNews[i]);
          }
        }
        if (this.isEdit && files.length >= 1) {
          for (let i = 0; i < files.length; i++) {
            _data.append(`imageNews${i}`, files[i]);
          }
        } else {
          for (let i = 0; i < files.length; i++) {
            _data.append(`imageNews${i}`, files[i]);
          }
        }
        _data.append("isEnable", true);
        _data.append("textNews", textNews);
        _data.append("featured", this.featured);
        this.modifyVideo ? _data.append("thumbnail", this.img) : "";
        _data.append("forUser", this.forUser && JSON.stringify(this.forUser));
        this.dateFrom ? _data.append("dateFrom", this.dateFrom) : _data.append("undefinedTime", "handmade");
        this.dateTo ? _data.append("dateTo", this.dateTo) : "";
        if (this.isEdit) {
          _data.append("id", this.isEditData._id);
          _data.append("reminder", this.reminder);
        }
        if (this.dateFrom && this.isEdit) {
          _data.append("undefinedTime", "temporary");
        }
        let res;
        alerts.dataSending();
        this.onLoaderPercent(0);
        !this.isEdit ? (res = await axios.post("/news", _data)) : (res = await axios.put("/news", _data));
        if (res.data.success) {
          alerts.dataSendSuccess({ message: res.data.success });
          setTimeout(() => {
            this.$router.push({ path: "/newsList" });
          }, 1000);
        } else {
          this.onLoaderPercent("");
          alerts.dataSendError({ message: res.data.noSuccess || res.data.error });
        }
      },
      convert(arrayImagenes) {
        const dataTransfer = new DataTransfer();
        for (let i = 0; i < arrayImagenes.length; i++) {
          const { data, type, name } = arrayImagenes[i];
          function base64toFile(base64, filename, type) {
            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            return new File([byteArray], filename, { type });
          }
          const file = base64toFile(data, name, type);
          dataTransfer.items.add(file);
        }
        const input = document.getElementById("imageFileValid");
        input.files = dataTransfer.files;
        for (let i = 0; i < input.files.length; i++) {
          this.readFileAndRender(input.files[i]);
        }
      },
    },
    async beforeMount() {
      this.$nextTick(() => {
        document.getElementById("text-area-news").addEventListener("paste", function(event) {
          event.preventDefault();

          let textoPegado = event.clipboardData.getData("text");

          let lineas = textoPegado.split("\n");

          let div = document.getElementById("text-area-news");
          let selection = window.getSelection();
          let range = selection.getRangeAt(0);
          range.deleteContents();

          for (let i = 0; i < lineas.length; i++) {
            if (i > 0) {
              div.appendChild(document.createElement("br"));
            }
            let textNode = document.createTextNode(lineas[i]);
            div.appendChild(textNode);
          }

          range.selectNodeContents(div);
          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
        });
      });
      if (this.$route.query.new) {
        this.fetchOffices();
        let res = await axios.get(`/news/id?id=${this.$route.query.new}`);
        this.isEditData = res.data;
        for (let index = 0; index < this.isEditData.headquarters?.length; index++) {
          const inputElements = document.querySelectorAll(`input[name="office-${this.isEditData.headquarters[index]}"]`);
          inputElements.forEach((elem, index) => {
            elem.setAttribute("checked", "true");
          });
        }
        this.isEdit = true;
        this.isEdit ? (this.category = this.isEditData.category) : "";
        this.dateFrom = this.isEditData?.dateFrom;
        this.dateTo = this.isEditData?.dateTo;
        this.dateTo = this.dateTo?.slice(0, 10);
        this.featured = this.isEditData?.featured;
        this.reminder = this.isEditData?.reminder;
        this.title = this.isEditData?.titleNews ?? "";
        this.subtitle = this.isEditData?.subtitleNews ?? "";
        this.forUser = this.isEditData?.forUser ?? "";
        this.selectedNumber = this.isEditData?.skip;
        if (this.isEdit) this.link = res.data.videoNews;
      }
      if (this.isEditData?.city.includes("allcity") || !this.isEdit) {
        this.checkall = true;
      }
      if (this.isEditData?.userType.includes("allusers") || !this.isEdit) {
        this.roleAll = true;
      }
      this.mountedNews = true;
    },
    mounted() {
      this.fetchCities();
      this.getCategories();
      let textArea = document.getElementById("text-area-news");
      textArea.addEventListener("paste", (event) => {
        let paste = (event.clipboardData || window.clipboardData).getData("text/html");
        paste = paste.replaceAll(/font-family:[^;']*(;)?/gi, "");
        document.execCommand("insertHTML", false, paste);
        this.showEditText = true;
        event.preventDefault();
      });
    },
  };
</script>

<template>
  <div class="newNews" v-bind="initSocketEvents">
    <div class="masked" v-if="isLoading">
      <div class="newNews__spinner" v-if="isLoading">
        <p class="spinner"></p>
        <p class="text">Cargando...</p>
      </div>
    </div>
    <div class="mask" v-if="showModalCategory"></div>
    <ModalCreateCategory v-if="showModalCategory" @clicked="closeModal" :data="showModalCategory" class="news__category" />
    <form class="newNews__card" v-bind:key="resetKey" id="form-news-data" enctype="multipart/form-data">
      <div class="newNews__header" v-html="isEdit ? 'Actualizar Noticia' : 'Crear Noticia'"></div>
      <v-md-date-range-picker class="calendarColor" ref="rangepickernews" @change="handleDate" :start-date="dateFrom || undefined" :end-date="dateTo || undefined"></v-md-date-range-picker>
      <div class="newNews__toggle">
        <div class="featured">
          <div class="newNews__title newNews__title--toggle">Destacada:</div>
          <ToggleInput @isChecked="featuredToggle" :checked="isEditData ? isEditData.featured : false" />
        </div>
        <div v-if="isEditData" class="memory">
          <div class="newNews__title newNews__title--toggle">Recuerdo:</div>
          <ToggleInput @isChecked="featureToggle" :checked="isEditData ? isEditData.reminder : false" />
        </div>
        <div class="newNews__showHome">
          <label class="newNews__title newNews__title--toggle" for="numberSelect">Mostrar en home:</label>
          <select class="newNews__selectShowHome" id="numberSelect" v-model="selectedNumber" name="skip">
            <option v-for="number in 10" :key="number" :value="number">
              {{ number }}
            </option>
          </select>
        </div>
      </div>
      <div class="newNews__item">
        <div class="newNews__title">Título</div>
        <input class="newNews__inputs" type="text" name="title" v-model="title" />
      </div>
      <div class="newNews__item">
        <div class="newNews__title">Subtítulo</div>
        <input class="newNews__inputs" type="text" name="subtitle" v-model="subtitle" />
      </div>
      <div class="newNews__item">
        <div class="newNews__title">Texto de la Noticia</div>
        <div class="newNews__textEditor" v-show="showEditText">
          <button class="newNews__buttonsClass--button" v-for="(img, idx) in imgs" :key="idx" @click.prevent="formatDoc(img.params)">
            <i :class="img.icon"></i>
          </button>
          <span class="newNews__size">
            <button class="newNews__buttonsClass--button" @click.prevent="formatDoc('fontSize')">
              <i class="fa fa-text-height"></i>
            </button>
            <select @click="formatDoc('fontSize')" v-model="fontSize" name="select">
              <option v-for="ind in 7" :key="ind" :value="ind">{{ elemetsizeText[ind] }}</option>
            </select>
          </span>
          <input class="newNews__colorPicker" v-if="colorShow" id="color" @blur="formatDoc('foreColor', color)" v-model="color" type="color" />
        </div>

        <div class="newNews__textarea" id="text-area-news" @focus="showEditText = true" contenteditable="true" nobars v-html="isEditData ? isEditData.textNews : ''"></div>
      </div>
      <div class="newNews__category">
        <div class="newNews__category--select">
          <div class="newNews__title">Seleccionar categoria</div>
          <select name="category" id="selectCategory" class="newNews__category--options" v-model="category">
            <option disabled value="">Por favor selecciona una</option>
            <option class="options" :value="category.newCategory" v-for="(category, index) in categories" :key="index">
              {{ category.newCategory }}
            </option>
          </select>
          <span class="newNews__category--error" v-if="error">Debes selecionar una categoria</span>
        </div>
        <div class="newNews__category--create">
          <div class="newNews__category--btnCreate" @click="showModal">
            <iconic name="plus"></iconic>
            <span class="tooltiptext">Crear categoria</span>
          </div>
        </div>
      </div>
      <ModalCut v-if="false" />
      <div class="newNews__item" v-bind:key="resetKeyImg">
        <div class="newNews__cont">
          <div class="newNews__title">Imagenes y videos para slider</div>
          <span class="newNews__btn" @click="showImages" v-if="isEdit && !addImage">Cargar fotos</span>
        </div>
        <input
          :class="['newNews__file', isEdit ? 'newNews__file--transparent' : '']"
          type="file"
          accept="image/*, video/*"
          id="imageFileValid"
          @change="GetFileInfo()"
          multiple
          :placeholder="isEdit && isEditData.imageNews ? 'Editar fotos' : 'Imágenes de tipo (png, jpge, jpg...)'"
          :disabled="addImage"
        />
        <div class="newNews__category--create" v-if="addImage">
          <input
            :class="['newNews__file', isEdit ? 'newNews__file--transparent' : '']"
            type="file"
            accept="image/*, video/*"
            id="imageAdd"
            @change="moreImages()"
            multiple
            :placeholder="isEdit && isEditData.imageNews ? 'Añadir mas' : 'Añadir mas'"
          />
        </div>
        <div id="modal"></div>
      </div>
      <div class="container__btn_deleted">
        <span @click="GetFileInfo()" class="closeSlider">x</span>
        <span class="container_trash">
          <i v-if="show_btn_delet" class="fa fa-trash btn_deleted" aria-hidden="true"></i>
        </span>
      </div>
      <div id="visorArchivo"></div>
      <div class="newNews__itemVideoContainer">
        <div class="newNews__item newNews__item--video" :key="resetKeyVideo">
          <div class="newNews__title">Crear poster</div>
          <input
            :class="['newNews__file', isEdit ? 'newNews__file--transparent' : '']"
            type="file"
            accept="video/*"
            name="videoNews"
            id="videoFileValid"
            @change="validFileAlert('video', 'videoFileValid')"
            @input="CreateImgsSelect($event)"
            :placeholder="isEdit && isEditData.videoNews ? cutName(isEditData.videoNews) : 'Videos de tipo (mp4, flv, mov...)'"
          />
        </div>
        <div class="newNews__item newNews__item--thumbnail" :key="resetKeyThumbnail" v-if="!modifyVideo">
          <div class="newNews__title">Imagen del video</div>
          <input
            :class="['newNews__file', isEdit ? 'newNews__file--transparent' : '']"
            type="file"
            accept="image/*"
            id="thumbnailFileValid"
            name="thumbnail"
            @change="validFileAlert('image', 'thumbnailFileValid')"
            :placeholder="isEdit && isEditData.thumbnail ? cutName(isEditData.thumbnail) : 'Imágenes de tipo (png, jpge, jpg...)'"
          />
        </div>
      </div>
      <div class="newNews__containerVideo" id="containerVideoPath"></div>
      <button v-if="showButton || modifyVideo" @click.prevent="createElementVideo" class="newNews__buttonConfirm">
        Crear póster
      </button>
      <i class="fa fa-hand-o-down"></i>
      <div class="newNews__title" v-if="modifyVideo">Imagen del video</div>
      <div id="contentimage" v-if="modifyVideo" class="newNews__title">
        <div class="newNews__recicleButton" v-if="showRecicleButton">
          <button class="newNews__recicleButton-button" @click.prevent="deletePoster()">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </div>

      <div id="spinner"></div>
      <div class="newNews__item" v-bind:key="resetKeyFile">
        <div class="newNews__title">Archivo</div>
        <input
          :class="['newNews__file', isEdit ? 'newNews__file--transparent' : '']"
          type="file"
          accept="application/pdf,.doc,.docx,.xlsx,.pptx,.txt,application/msword"
          name="documentNews"
          id="fileFileValid"
          @change="validFileAlert('file', 'fileFileValid')"
          :placeholder="isEdit && isEditData.documentNews ? cutName(isEditData.documentNews) : 'Archivos de tipo (pdf, word, docx...)'"
        />
      </div>
      <div class="newNews__checkbox">
        <div class="newNews__item">
          <div class="newNews__title" v-if="initCheck">Sedes</div>
          <div class="newNews__group">
            <div v-for="(office, index) in officesData" :key="index">
              <input
                class="mycheck newNews__check"
                type="checkbox"
                :id="`office-${index}`"
                :name="`office-${office.officeName}`"
                :value="office.officeName"
                :checked="isEdit ? isEditData.city.includes('allcity') || isEditData.headquarters.includes(office.officeName) : initCheck"
              />
              <label class="newNews__labelCheck" :for="`office-${index}`">{{ office.officeName }}</label>
            </div>
          </div>

          <div class="newNews__title">Ciudades</div>
          <div class="newNews__group">
            <div class="newNews__itemCheck">
              <input
                class="mycheck newNews__check"
                type="checkbox"
                id="cities-all"
                name="cities-allcity"
                @change="change($event, '.mycheck.newNews__check.city')"
                value="allcity"
                :checked="isEdit ? isEditData.city.includes('allcity') : initCheck"
              />
              <label class="newNews__labelCheck" for="cities-all">Todas</label>
            </div>
            <div class="newNews__itemCheck" v-for="(city, idx) in cities" :key="idx">
              <input
                class="mycheck newNews__check city"
                type="checkbox"
                :id="`cities-${idx}`"
                :name="`cities-${city}`"
                :value="city"
                :checked="isEdit || checkall ? (checkall ? true : isEditData.city.includes(city)) : false"
                @change="onChangeItem($event, 'cities-all')"
                @click="checkHeadquaters"
              />
              <label class="newNews__labelCheck" :for="`cities-${idx}`">{{ city }}</label>
            </div>
          </div>
        </div>

        <div class="newNews__item">
          <div class="newNews__title">Usuarios</div>
          <div class="newNews__group">
            <div class="newNews__itemCheck">
              <input
                class="mycheck newNews__check"
                type="checkbox"
                id="users-all"
                name="users-allUsers"
                value="allusers"
                :checked="isEdit ? isEditData.userType.includes('allusers') : initCheck"
                @change="change($event, '.mycheck.newNews__check.role')"
              />
              <label class="newNews__labelCheck" for="users-all">Todos</label>
            </div>
            <div class="newNews__itemCheck" v-for="(role, idx) in roles" :key="idx">
              <input
                class="mycheck newNews__check role"
                type="checkbox"
                :id="`users-${idx}`"
                :name="`users-${idx}`"
                :value="idx"
                :checked="isEdit || roleAll ? (roleAll ? true : isEditData.userType.includes(idx)) : false"
                @change="onChangeItem($event, 'users-all')"
              />
              <label class="newNews__labelCheck" :for="`users-${idx}`" capitalize>{{ role }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="newNews__item">
        <NewsFor @select="selectedUsers" :currentUsers="forUser" v-if="mountedNews" />
      </div>
      <div class="newsButtons">
        <router-link to="/newsList">
          <button :class="['newsButtons__newsbtn', isEdit ? 'newsButtons__newsbtn--backEdit' : 'newsButtons__newsbtn--back']">
            Cancelar
          </button>
        </router-link>
        <button
          :disabled="!isLoadingThubnail"
          class="newsButtons__newsbtn newsButtons__newsbtn--create"
          @click.prevent="sendFormNews()"
          type="submit"
          v-html="isEdit ? 'Actualizar' : 'Crear'"
        ></button>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
  .masked {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .newNews__spinner {
      position: relative;
      text-align: center;
      .spinner {
        border: 10px solid rgba(0, 0, 0, 0.1);
        border-right-color: $primary_color;
        top: 30%;
        width: 130px;
        height: 130px;
      }
      .text {
        margin-top: -70px;
        color: $primary_color;
        font-weight: bold;
      }
    }
  }
  #imageAdd {
    position: absolute;
    margin-left: 87%;
    margin-top: -61px;
    width: 13.5%;
    background-color: #bd0909;
  }
  .img-sample {
    border: 1px solid #dee2e6;
    overflow: hidden;
    border-radius: 6px;
  }
  .content-imagen-cropper {
    height: 400px;
    width: 100%;
  }
  .cropper-wrap-box {
    overflow: visible;
  }
  .img-cropper {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .mask {
    width: 100%;
    height: 146vh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.144);
    top: 0;
    left: 0;
    z-index: 10;
  }
  .news__category {
    position: absolute;
    top: 660px;
    right: 1.5%;
  }
  .visorArchivo {
    position: relative;
  }
  .container__btn_deleted {
    margin-top: -20px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 0px 10px 10px;
    .container_trash {
      position: relative;
      width: 35px;
      height: 30px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        border: 1px solid $primary_color;
        color: #fff;
      }
      &:hover:before {
        content: "Selecionar imagenes para borrar";
        position: absolute;
        top: -40px;
        transform: translateX(-40%);
        background-color: #000;
        color: #fff;
        padding: 5px;
        font-size: 12px;
        white-space: nowrap;
        border-radius: 4px;
      }
      .btn_deleted {
        position: relative;
        color: lightgray;
        color: $primary_color;
        top: 4px;
        &:hover {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
    .borrar {
      border: 1px solid $primary_color;
      color: $primary_color;
      border-radius: 6px;
      margin-bottom: 5px;
      margin-top: -2px;
      padding-top: 4px;
      padding: 3px 5px 0 5px;
      &:hover {
        background-color: $primary_color;
        color: #fff;
        cursor: pointer;
      }
    }
    .closeSlider {
      position: relative;
      top: -6px;
      font-size: 25px;
      font-weight: bold;
      cursor: pointer;
      display: none;
      margin-bottom: -20px;
      &:hover {
        color: $primary_color;
      }
      &:hover:before {
        content: "Cerrar editor imagenes";
        position: absolute;
        top: -30px;
        transform: translateX(-50%);
        background-color: #000;
        color: #fff;
        padding: 5px;
        font-size: 12px;
        font-weight: 100;
        white-space: nowrap;
        border-radius: 4px;
      }
    }
  }
  .visor {
    border-radius: 5px;
    background-color: #000;
    object-fit: cover;
    margin-bottom: 15px;
    margin-left: 15px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 2px 5px #d8d8d8;
    }
  }
  .activeCardImg {
    border: $primary-color 4px outset;
  }
  .newsButtons {
    margin-top: 25px;
    &__newsbtn {
      @include ButtonStyle2();
      border-radius: 5px;
      &:focus {
        outline: none;
      }
      &:active {
        transform: scale(0.97);
      }
      &:hover {
        opacity: $opacity-button;
      }
      &--create {
        display: block;
        width: 100%;
        margin-bottom: $mpadding;
      }
      &--clear {
        background: $matisse;
        width: 45%;
      }
      &--back {
        background: $chicago;
        width: 100%;
        margin-right: 3%;
      }
      &--backEdit {
        background: $chicago;
        width: 45%;
        margin-right: 3%;
      }
    }
  }
  .newNews {
    width: 100%;
    font-size: 16px;
    font-family: $first_font;
    &__showHome {
      @include Flex();
    }
    &__selectShowHome {
      max-width: 50px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid $alto;
      height: 30px;
      padding-left: 10px;
      outline: none;
      color: $chicago;
    }
    &__colorPicker {
      width: 20px;
      height: 21px;
      margin: 0 3px;
    }
    [required] {
      .newNews__title {
        position: relative;
        display: inline-block;
        &::after {
          content: "*";
          color: $primary_color;
          font-size: 12px;
          position: absolute;
          right: -8px;
        }
      }
    }
    ol,
    ul {
      margin-left: 13px;
    }
    &__category {
      display: flex;
      gap: 10px;
      &--select {
        width: 650px;
        position: relative;
      }
      &--error {
        color: $primary_color;
      }
      &--options {
        .options {
          text-transform: capitalize;
        }
        max-width: 650px;
        width: 100%;
        height: 30px;
        border-radius: 5px;
        border: 1px solid $alto;
        height: 36px;
        padding-left: 10px;
        outline: none;
        color: $chicago;
      }
      &--create {
        position: relative;
        padding: 25px 0 0 10px;
      }
      &--btnCreate {
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        background-color: $primary_color;
        .tooltiptext {
          position: absolute;
          background-color: #000;
          opacity: 0.8;
          width: 135px;
          top: 0px;
          left: 40px;
          padding: 3px 0 3px 5px;
          border-radius: 6px;
          visibility: hidden;
          &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: -4px;
            margin-left: -10px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent #000 transparent transparent;
          }
        }
        :nth-child(1) {
          margin-left: 3.7px;
          margin-top: 3.5px;
        }
        &:hover {
          transition: all 0.3s ease-in-out;
          background: $primary_color;
          transform: scale(1.05);
          .tooltiptext {
            visibility: visible;
          }
        }
      }
    }
    &__containerVideos {
      justify-content: center;
      max-width: 100%;
      height: 425px;
    }
    &__size {
      width: auto;
      height: auto;
      margin: 0 5px;
      border: 1px solid gray;
      padding: 3px;
      margin-bottom: 2px;
      font-size: 11px;
    }
    &__buttonConfirm {
      border: none;
      background-color: $primary-color;
      padding: 1px 33px;
      color: white;
      position: relative;
      bottom: 419px;
    }
    &__textEditor {
      margin-bottom: 3px;
    }
    &__card {
      max-width: 1440px;
      margin: 0 auto;
      margin-top: 10px;
      border-radius: 7px;
      background-color: $white;
      box-shadow: 0 2px 5px $alto;
      padding: $mpadding/2 $mpadding * 2 $mpadding * 1.5;
    }
    &__btons {
      display: flex;
    }
    &__recicleButton {
      &-button {
        border: none;
        position: relative;
        top: 25px;
        left: 125px;
        color: #bd0909;
        background: transparent;
      }
    }
    &__imgContentVideo {
      width: 100px;
      height: auto;
      margin: 5px 5px;
      border-radius: 5px;
      &:focus {
        opacity: 0.8;
      }
      cursor: pointer;
    }
    &__calendar {
      width: 100%;
      border: none;
      color: $black;
      background: transparent;
      font-family: $first_font;
      color: $chicago;
      &:focus {
        outline: none;
      }
      .icoCalendar {
        font-size: 20px;
      }
    }
    &__buttonsClass {
      display: flex;
      &--button {
        margin: 0 3px;
        border: none;
        background-color: transparent;
      }
    }
    &__clearDate {
      margin-left: 10px;
      padding: 0 10px;
      border-radius: 5px;
      color: $white;
      background: $lipstick;
      border: none;
      &:focus {
        outline: none;
      }
      &:active {
        transform: scale(0.97);
      }
      &:hover {
        opacity: $opacity-button;
      }
    }
    &__label {
      font-size: 11.5px;
      display: flex;
      height: 36px;
      padding: 0 $mpadding;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      border: 1px solid $alto;
    }
    &__header {
      margin-top: $mpadding;
      font-size: 2em;
      font-weight: bold;
      color: $lipstick;
      font-family: $sec_font;
    }
    &__itemVideoContainer {
      display: flex;
      flex-direction: row;
    }
    &__item {
      display: block;
      margin: 10px auto;
      &--calendar {
        margin-top: $mpadding * 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &--video {
        margin-right: $mpadding/3;
        width: 100%;
      }
      &--thumbnail {
        width: 100%;
      }
    }
    &__cont {
      display: flex;
      justify-content: space-between;
      font-size: 1em;
      color: $chicago;
      margin-bottom: 5px;
      .newNews__btn {
        cursor: pointer;
        margin-right: 5px;
        border: 1px solid $alto;
        padding: 0px 5px;
        border-radius: 6px;
        background: #fff;
        &:hover {
          background-color: $primary_color;
          color: #fff;
          opacity: 1;
          transition: ease-in-out 0.4s;
        }
      }
    }
    &__title {
      display: flex;
      font-weight: bold;
      font-size: 1em;
      color: $chicago;
      &--toggle {
        margin-right: $mpadding;
      }
    }
    &__inputs {
      width: 100%;
      border-radius: 5px;
      border: 1px solid $alto;
      height: 36px;
      padding-left: 10px;
      color: $chicago;
    }
    &__textarea {
      width: 100%;
      border-radius: 5px;
      border: 1px solid $alto;
      padding: 5px 10px;
      color: $chicago;
      min-height: 220px;
      height: auto;
      font-family: $first_font;
      color: $chicago;
      overflow-y: auto;
      &:focus {
        outline: none;
      }
    }
    &__file {
      width: 100%;
      height: 36px;
      background: $chicago;
      color: $white;
      font-size: 0.9em;
      border-radius: 5px;
      align-items: center;
      padding-top: 7px;
      &--transparent {
        color: transparent;
      }
      &::-webkit-file-upload-button {
        display: none;
      }
      &:hover {
        cursor: pointer;
      }
      &::before {
        content: attr(placeholder);
        margin-right: 10px;
        margin-left: 10px;
        font-size: 1em;
        color: $white;
      }
    }
    &__toggle {
      display: flex;
      align-items: center;
      gap: 30px;
      .featured {
        display: flex;
      }
      .memory {
        display: flex;
      }
    }
    &__itemCheck {
      display: flex;
      align-items: center;
    }
    &__group {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 12px;
    }
    &__labelCheck {
      color: $black;
      font-size: 1em;
      margin-right: 5px;
    }
    &__check {
      margin-right: 5px;
    }
    &__checkbox {
      margin: 10px 0;
    }
    .mycheck {
      width: 18px;
      height: 18px;
      border-radius: 5px;
    }
    .mdrp__activator {
      display: none;
    }
    .mdrp__panel.dropdown-menu[data-v-7863e830] {
      margin-top: 48px;
      left: -24px;
    }
    .mdrp__panel .calendar-table.right[data-v-7863e830] {
      display: none;
    }
    .calendarColor {
      color: $black;
      font-family: $first_font;
    }
    table.calendar-table td.calendar-cell.active .calendar-cell__content[data-v-89ba891e],
    table.calendar-table td.calendar-cell.active:hover .calendar-cell__content[data-v-89ba891e] {
      background-color: $lipstick;
    }
    .ranges ul[data-v-4e185066] .active {
      color: $lipstick;
    }
    .ranges ul[data-v-4e185066] {
      display: none;
    }
    @media screen and (min-width: $mobile_small_width) {
      .mdrp__panel.dropdown-menu[data-v-7863e830] {
        left: 0;
      }
      &__label {
        font-size: 16px;
      }

      &__card {
        margin-bottom: 75px;
      }
    }
    @media (max-width: $mobile_width) {
      .mask {
        width: 100%;
        height: 230vh;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.144);
        top: 0;
        left: 0;
        z-index: 10;
      }
      &__itemVideoContainer {
        flex-direction: column;
      }
    }
    @media (min-width: 768px) {
      &__card {
        margin-bottom: $space-0;
      }
    }
  }
  @media (min-width: $mobile_small_width) {
    .newsButtons {
      &__newsbtn {
        &--create {
          width: 40%;
          display: inline;
          margin-bottom: $space-0;
        }
        &--clear {
          width: 40%;
          margin-left: $space-24;
        }
        &--backEdit,
        &--back {
          width: 40%;
          margin-left: $space-24;
        }
      }
    }
  }
  @media (min-width: $pocket_width) {
    .newsButtons {
      width: 100%;
      text-align: center;
      margin-bottom: $mpadding/2;
      &__newsbtn {
        &--create {
          width: 25%;
          display: inline;
          margin-bottom: 0;
        }
        &--clear {
          width: 25%;
          margin-left: 24px;
        }
        &--backEdit,
        &--back {
          width: 25%;
          margin-left: 24px;
        }
      }
    }
    .newNews {
      &__group {
        display: flex;
      }
    }
  }
  @media (min-width: 768px) {
    .news__category {
      position: absolute;
      top: 660px;
      left: 45%;
    }
  }
</style>
